body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #1d202f;
  background-image: url(carbon-fibre.png);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
