h1 {
  font-family: 'Jura', sans-serif;
  font-weight: 200;
  color: #f6fffe;
}

h2 {
  font-family: 'Jura', sans-serif;
  font-weight: 200;
  color: #f6fffe;
}

h3 {
  font-family: 'Jura', sans-serif;
  font-weight: 100;
  color: #f6fffe;
}

h4 {
  font-family: 'Jura', sans-serif;
  color: #f6fffe;
}

body, input {
  font-family: 'Jura', sans-serif;
  color: #f6fffe;
}

#scroll {
  overflow-y: 'scroll';
  border: '1px solid black';
  height: '69vh';
}

#footer {
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #f6fffe;
  text-align: center;
  border: 1px;
  border-style: solid;
  border-color: #f6fffe;
  padding: 5px;
}

#navbar button {
  background-color: Transparent;
  background-repeat:no-repeat;
  cursor:pointer;
  overflow: hidden;  
}

#navbar button:hover {
  border-color: #FFD700;
  color: #FFD700;
}

@media screen and (max-height: 600px) {
  #pageHeader {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}

@media screen and (max-height: 412px) {
  #searchBox {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
  #pageHeader {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
  #footer {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}